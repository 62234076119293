import * as data from '../data.json'

// prettier-ignore
interface Chapter {
  id: number;
  name: string;
  imageSrc: string;
  href?: string;
  translations: { [language: string]: string };
}

// prettier-ignore
interface Vocab {
  id: number;
  chapter: number;
  translations: { [language: string]: string };
}

export function getLanguages() {
  return data.languages
}

export function getRightToLeftLanguages() {
  return getLanguages()
    .filter((l) => l.rtl)
    .map((l) => l.id)
}

export function getChapters(): Chapter[] {
  return data.chapters
}

export function getVocab(id: number): Vocab | undefined {
  return data.vocabs.find((v) => v.id === id)
}

export function findVocabs(anyTranslation: string): Vocab[] {
  return data.vocabs.filter((v) => {
    return Object.values(v.translations).some((t) =>
      t.toLowerCase().includes(anyTranslation.toLowerCase())
    )
  })
}

export function getVocabs(): Vocab[] {
  return data.vocabs
}

export function getSportsClubParagraphs(language: string): string[] {
  return data.sports_club_paragraphs[language]
}

// prettier-ignore
export function getChapter(id: number):
  | {
      chapter: Chapter;
      nextId: number | null;
      prevId: number | null;
      vocabs: Vocab[];
    }
  | undefined {
  const chapters = getChapters()
  const chapterIndex = chapters.findIndex((chapter) => chapter.id === id)

  if (chapterIndex === -1) {
    return undefined
  }

  return {
    chapter: chapters[chapterIndex],
    vocabs: data.vocabs.filter((vocab) => vocab.chapter == id),
    nextId:
      chapterIndex === chapters.length - 1
        ? chapters[0].id
        : chapters[chapterIndex + 1].id,
    prevId:
      chapterIndex === 0
        ? chapters[chapters.length - 1].id
        : chapters[chapterIndex - 1].id
  }
}
